<template>
    <div class="privacy">
        <navbar/>
        <div class="privacy__content">
            <div class="privacy__content__maintitle">
                <h1> Polityka prywatności Sklepu Kompre („Sklep”)</h1>
            </div>
            <div class="privacy__content__info">
                <h3>Drogi użytkowniku</h3>
                <span>
                    Dbamy o Twoją prywatność i chcemy, abyś w czasie korzystania z naszych usług czuł się komfortowo. Dlatego też poniżej prezentujemy Ci najważniejsze informacje o zasadach przetwarzania przez nas Twoich danych osobowych oraz plikach cookies, które są wykorzystywane przez nasz Sklep. Informacje te zostały przygotowane z uwzględnieniem RODO, czyli ogólnego rozporządzenia o ochronie danych. 
                </span>
            </div>
            <div class="privacy__content__info">
                <h3>Administrator danych osobowych</h3>
                <span>
                    KOMPRE Sp. z o.o. z siedzibą pod adresem Rogaszyce ul. Wesoła 37, 63-500 Ostrzeszów,
                    wpisana do Krajowego Rejestru Sądowego - rejestru przedsiębiorców przez SĄD
                    REJONOWY POZNAŃ - NOWE MIASTO I WILDA W POZNANIU, IX WYDZIAŁ
                    GOSPODARCZY KRAJOWEGO REJESTRU SĄDOWEGO, pod nr KRS 0000636486, NIP
                    5140199932, nr REGON 365361778.
                    Jeśli chcesz skontaktować się z nami w związku z przetwarzaniem przez nas Twoich danych
                    osobowych, napisz do nas na adres e-mail: sklep@kompre.pl.
                </span>
            </div>
            <div class="privacy__content__info">
                <h3>Twoje uprawnienia</h3>
                <span>
                Przysługuje Ci prawo żądania: 
                dostępu do Twoich danych osobowych, w tym uzyskania kopii Twoich danych (art. 15 RODO lub - jeśli ma to zastosowanie - art. 13 ust. 1 lit. f RODO), 
                ich sprostowania (art. 16 RODO), 
                usunięcia (art. 17 RODO), 
                ograniczenia przetwarzania (art. 18 RODO), 
                przeniesienia danych do innego administratora (art. 20 RODO). 
                A także prawo: 
                wniesienia w dowolnym momencie sprzeciwu wobec przetwarzania Twoich danych: 
                z przyczyn związanych z Twoją szczególną sytuacją – wobec przetwarzania dotyczących Ciebie danych osobowych, opartego na art. 6 ust. 1 lit. f RODO (tj. na realizowanych przez nas prawnie uzasadnionych interesach) (art. 21 ust. 1 RODO); 
                jeżeli dane osobowe są przetwarzane na potrzeby marketingu bezpośredniego, w zakresie, w jakim przetwarzanie jest związane z takim marketingiem bezpośrednim (art. 21 ust. 2 RODO). 
                Skontaktuj się z nami, jeśli chcesz skorzystać ze swoich praw. Sprzeciw w odniesieniu do wykorzystywania przez nas plików cookies (o których przeczytasz poniżej) możesz wyrazić zwłaszcza za pomocą odpowiednich ustawień przeglądarki. 
                Jeśli uznasz, że Twoje dane są przetwarzane niezgodnie z prawem, możesz złożyć skargę do Prezesa Urzędu Ochrony Danych Osobowych. 
                </span>
            </div>
            <div class="privacy__content__info">
                <h3>Dane osobowe i prywatność</h3>
                <span>
                    Poniżej znajdziesz szczegółowe informacje na temat przetwarzania Twoich danych w
                    zależności od podejmowanych przez Ciebie działań.
                </span>
            </div>
            <div class="privacy__content__info">
                <h4>1. Złożenie zamówienia w Sklepie - cz. 1</h4>
                <span class="question">
                    W jakim celu?
                </span>
                <span class="answer">
                    Realizacja Twojego zamówienia 
                </span>
                <span class="question">
                    Na jakiej podstawie?
                </span>
                <span class="answer">
                    -Umowa sprzedaży lub umowa o dostarczenie treści cyfrowych (art. 6 ust. 1 lit. b RODO) 
                </span>
                <span class="answer">
                    -Obowiązek prawny, związany z rachunkowością, zobowiązujący nas do przetwarzania Twoich danych osobowych (art. 6 ust. 1 lit. c RODO) 
                </span>
                <span class="question">
                    Jak długo?
                </span>
                <span class="answer">
                    -Przez okres obowiązywania wyżej wymienionej umowy 
                </span>
                <span class="answer">
                    -Do momentu wygaśnięcia ciążącego na nas obowiązku prawnego, związanego z rachunkowością 
                </span>
                <span class="answer">
                    Ponadto, Twoje dane będą przetwarzane do upływu okresu, w którym możliwe jest dochodzenie roszczeń – przez Ciebie lub przez nas.
                </span>
                <span class="question">
                    Co się stanie jeśli nie podasz danych?
                </span>
                <span class="answer">Nie będziesz mieć możliwości złożenia zamówienia</span>
            </div>
            <div class="privacy__content__info">
                <h4>2. Złożenie zamówienia w Sklepie - cz. 2</h4>
                <span class="question">
                    W jakim celu?
                </span>
                <span class="answer">
                    Dostosowanie Sklepu do potrzeb Użytkowników, a także polepszenie jakości świadczonych przez nas usług, dzięki opiniom wystawianym przez Kupujących za pośrednictwem serwisu do badania satysfakcji  
                </span>
                <span class="question">
                    Na jakiej podstawie?
                </span>
                <span class="answer">
                  Nasz prawnie uzasadniony interes, polegający na przetwarzaniu Twoich danych w celu prowadzenia badań Twojej satysfakcji z naszych usług (art. 6 ust. 1 lit. f RODO)  
                </span>
                <span class="answer">
                    -Obowiązek prawny, związany z rachunkowością, zobowiązujący nas do przetwarzania Twoich danych osobowych (art. 6 ust. 1 lit. c RODO) 
                </span>
                <span class="question">
                    Jak długo?
                </span>
                <span class="answer">
                    -Jeśli nie wyrazisz swojej opinii - przez czas wynoszący 30 dni od dokonania przez Ciebie zakupu lub do momentu, w którym uwzględnimy Twój sprzeciw wobec przetwarzania; jeśli natomiast wyrazisz swoją opinię - do momentu jej usunięcia lub do momentu, w którym uwzględnimy Twój sprzeciw wobec przetwarzania* Ponadto, Twoje dane będą przetwarzane do upływu okresu, w którym możliwe jest dochodzenie roszczeń - przez Ciebie lub przez nas 
                </span>
                <span class="answer">
                    * w zależności od tego, które ma zastosowanie w danym przypadku i które nastąpi najwcześniej 
                </span>
                <span class="question">
                    Co się stanie jeśli nie podasz danych?
                </span>
                <span class="answer">Nie uwzględnimy Twojej opinii dotyczącej naszego Sklepu w pracach nad jego dalszym rozwojem </span>
            </div>

            <div class="privacy__content__info">
                <h4>3. Założenie konta w Sklepie</h4>
                <span class="question">
                    W jakim celu?
                </span>
                <span class="answer">
                    Realizacja umowy o świadczenie usługi prowadzenia konta w Sklepie 
                </span>
                <span class="question">
                    Na jakiej podstawie?
                </span>
                <span class="answer">
                    Umowa o świadczenie usług (art. 6 ust. 1 lit. b RODO) 
                </span>
                <span class="question">
                    Jak długo?
                </span>
                <span class="answer">
                    Do momentu, w którym konto zostanie usunięte przez Ciebie lub przez nas na Twoje żądanie. Ponadto, Twoje dane będą przetwarzane do upływu okresu, w którym możliwe jest dochodzenie roszczeń – przez Ciebie lub przez na
                </span>
                <span class="question">
                    Co się stanie jeśli nie podasz danych?
                </span>
                <span class="answer">Nie będziesz mieć możliwości założenia konta i korzystania z jego funkcji, takich jak przeglądanie historii zamówień czy sprawdzenie statusu zamówienia </span>
            </div>

            <div class="privacy__content__info">
                <h4>4. Nawiązanie z nami kontaktu (np. w celu zadania pytania)</h4>
                <span class="question">
                    W jakim celu?
                </span>
                <span class="answer">
                    Obsługa Twoich zapytań lub zgłoszeń 
                </span>
                <span class="question">
                    Na jakiej podstawie?
                </span>
                <span class="answer">
                    Umowa lub działania podejmowane na Twoje żądanie, zmierzające do jej zawarcia (art. 6 ust. 1 lit. b RODO) – w przypadku, gdy Twoje zapytanie lub zgłoszenie dotyczy umowy, której jesteśmy lub możemy być stroną 
                </span>
                <span class="answer">
                    Nasz prawnie uzasadniony interes, polegający na przetwarzaniu Twoich danych w celu prowadzenia z Tobą komunikacji (art. 6 ust. 1 lit. f RODO) – jeżeli Twoje zapytanie lub zgłoszenie nie ma związku z umową 
                </span>
                <span class="question">
                    Jak długo?
                </span>
                <span class="answer">
                    Przez czas trwania wiążącej nas umowy lub – jeśli umowa nie zostanie zawarta - do upływu okresu dochodzenia roszczeń
                </span>
                <span class="answer">
                    Do upływu okresu dochodzenia roszczeń – zobacz ostatnią tabelę tej sekcji - lub do momentu, w którym uwzględnimy Twój sprzeciw wobec przetwarzania
                </span>
                <span class="answer">
                    Ponadto, Twoje dane będą przetwarzane do upływu okresu, w którym możliwe jest dochodzenie roszczeń – przez Ciebie lub przez nas 
                </span>
                <span class="question">
                    Co się stanie jeśli nie podasz danych?
                </span>
                <span class="answer">
                    Nie będziemy mieli możliwości udzielenia odpowiedzi na Twoje zapytanie lub zgłoszenie
                </span>
            </div>

            <div class="privacy__content__info">
                <h4>5. Ustawienia przeglądarki lub inne zbliżone działanie zezwalające na prowadzenie działań analitycznych</h4>
                <span class="question">
                    W jakim celu?
                </span>
                <span class="answer">
                    Analiza sposobu korzystania i poruszania się przez Ciebie po stronie internetowej Sklepu, celem dostosowania strony do potrzeb i zachowania Użytkowników (więcej na ten temat przeczytasz w sekcji „Działania analityczne” i „Pliki cookies” Polityki prywatności) 
                </span>
                <span class="question">
                    Na jakiej podstawie?
                </span>
                <span class="answer">
                    Nasz prawnie uzasadniony interes, polegający na przetwarzaniu danych w podanym wyżej celu (art. 6 ust. 1 lit. f RODO) 
                </span>
                <span class="question">
                    Jak długo?
                </span>
                <span class="answer">
                    Do momentu wygaśnięcia ważności lub usunięcia przez Ciebie plików cookies, wykorzystywanych do celów analitycznych. 
                </span>
                <span class="question">
                    Co się stanie jeśli nie podasz danych?
                </span>
                <span class="answer">
                    Nie uwzględnimy Twoich preferencji dotyczących korzystania ze Sklepu w pracach nad jego rozwojem 
                </span>
            </div>


            <div class="privacy__content__info">
                <h4>6. Wyrażenie przez Ciebie zgody na otrzymywanie od nas treści marketingowych (np. informacji o ofertach specjalnych) </h4>
                <span class="question">
                    W jakim celu?
                </span>
                <span class="answer">
                    Wysyłka informacji marketingowych, zwłaszcza ofert specjalnych 
                </span>
                <span class="question">
                    Na jakiej podstawie?
                </span>
                <span class="answer">
                    Twoja zgoda na nasze działania marketingowe (art. 6 ust. 1 lit. a RODO)  
                </span>
                <span class="question">
                    Jak długo?
                </span>
                <span class="answer">
                    Do momentu wycofania przez Ciebie zgody – pamiętaj, w każdej chwili możesz wycofać zgodę. Przetwarzanie danych do momentu cofnięcia przez Ciebie zgody pozostaje zgodne z prawem.  
                </span>
                <span class="answer">
                    Ponadto, Twoje dane będą przetwarzane do upływu okresu, w którym możliwe jest dochodzenie roszczeń – przez Ciebie lub przez nas 
                </span>
                <span class="question">
                    Co się stanie jeśli nie podasz danych?
                </span>
                <span class="answer">
                    Nie będziesz otrzymywać naszych materiałów marketingowych, w tym informacji o naszych ofertach specjalnych 
                </span>
            </div>

            <div class="privacy__content__info">
                <h4>7. Subskrypcja Newslettera </h4>
                <span class="question">
                    W jakim celu?
                </span>
                <span class="answer">
                    wysyłanie newslettera 
                </span>
                <span class="question">
                    Na jakiej podstawie?
                </span>
                <span class="answer">
                    umowa o świadczenie usługi wysyłki newslettera (art. 6 ust. 1 lit. b RODO)  
                </span>
                <span class="question">
                    Jak długo?
                </span>
                <span class="answer">
                    do momentu, w którym wypiszesz się z naszego newslettera 
                </span>
                <span class="answer">
                    ponadto, Twoje dane będą przetwarzane do upływu okresu, w którym możliwe jest dochodzenie roszczeń – przez Ciebie lub przez nas 
                </span>
                <span class="question">
                    Co się stanie jeśli nie podasz danych?
                </span>
                <span class="answer">
                    nie będziesz mieć możliwości otrzymywania informacji dotyczących Sklepu i naszych usług 
                </span>
            </div>

            <div class="privacy__content__info">
                <h4>8. Podjęcie działania lub zaniechanie mogące powodować powstanie roszczeń związanych ze Sklepem lub naszymi usługami  </h4>
                <span class="question">
                    W jakim celu?
                </span>
                <span class="answer">
                    ustalenie, dochodzenie lub obrona ewentualnych roszczeń, związanych z zawartą umową lub świadczonymi usługami 
                </span>
                <span class="question">
                    Na jakiej podstawie?
                </span>
                <span class="answer">
                    nasz prawnie uzasadniony interes, polegający na przetwarzaniu danych osobowych we wskazanym powyżej celu (art. 6 ust. 1 lit. f RODO) 
                </span>
                <span class="question">
                    Jak długo?
                </span>
                <span class="answer">
                    do upływu okresu przedawnienia roszczeń lub do momentu, w którym uwzględnimy Twój sprzeciw wobec przetwarzania 
                </span>
                <span class="question">
                    Co się stanie jeśli nie podasz danych?
                </span>
                <span class="answer">
                    brak możliwości ustalenia, dochodzenia lub obrony roszczeń 
                </span>
            </div>

            <div class="privacy__content__info">
                <h3>Publikowanie danych</h3>
                <span>
                    Jeśli zdecydujesz się na opublikowanie komentarza, jego treść oraz Twój podpis będą widoczne dla innych użytkowników Sklepu. 
                    Nie ujawniamy innym użytkownikom Twojego adresu e-mail – chyba że sam to zrobisz.
                </span>
            </div>
            
            <div class="privacy__content__info">
                <h3>Działania analityczne</h3>
                <span>
                    W ramach Sklepu prowadzimy działania analityczne, mające na celu zwiększenie jego intuicyjności i przystępności – w odniesieniu do Ciebie będzie to miało miejsce, jeśli zezwolisz na takie działania. W ramach analizy będziemy brać pod uwagę sposób, w jaki poruszasz się po Sklepie – a więc np. to, ile czasu spędzasz na danej podstronie, czy w które miejsca w Sklepie klikasz. Dzięki temu możemy dostosować układ i wygląd Sklepu oraz zamieszczane w nim treści do potrzeb Użytkowników. 
                </span>
            </div>

            <div class="privacy__content__info">
                <h3>Bezpieczeństwo danych</h3>
                <span>
                    Przetwarzając Twoje dane osobowe stosujemy środki organizacyjne i techniczne zgodne z właściwymi przepisami prawa, w tym stosujemy szyfrowanie połączenia za pomocą certyfikatu SSL. 
                </span>
            </div>

            <div class="privacy__content__info">
                <h3>Pliki Cookies</h3>
                <span>
                    Nasz Sklep, jak większość witryn internetowych, korzysta z tzw. plików cookies (ciasteczek). Pliki te: 
                    są zapisywane w pamięci Twojego urządzenia (komputera, telefonu, itd.); 
                    nie powodują zmian w ustawieniach Twojego urządzenia. 
                    W tym Sklepie ciasteczka wykorzystywane są w celach: 
                    zapamiętywania Twojej sesji 
                    statystycznych 
                    udostępniania funkcji Sklepu 
                    Aby dowiedzieć się, jak zarządzać plikami cookies, w tym jak wyłączyć ich obsługę w Twojej przeglądarce, możesz skorzystać z pliku pomocy Twojej przeglądarki. Z informacjami na ten temat możesz zapoznać się wciskając klawisz F1 w przeglądarce. Ponadto odpowiednie wskazówki znajdziesz na następujących podstronach, w zależności od przeglądarki, której używasz: 
                    Firefox 
                    Chrome 
                    Safari 
                    Internet Explorer / Microsoft Edge 
                    Pliki cookies nie będą przetwarzane przez nas dłużej niż 12 miesięcy od ostatniej wizyty w Sklepie. 
                    Korzystając z odpowiednich opcji Twojej przeglądarki, w każdej chwili możesz: 
                    usunąć pliki cookies, 
                    blokować wykorzystanie plików cookies w przyszłości. 
                    W takich przypadkach nie będziemy ich już dłużej przetwarzać.
                </span>
            </div>

            <div class="privacy__content__info">
                <h3>Usługi zewnętrzne, odbiorcy danych</h3>
                <span>
                    Korzystamy z usług podmiotów zewnętrznych, które wspierają nas w prowadzeniu działalności. Powierzamy im do przetwarzania Twoje dane – podmioty te przetwarzają dane wyłącznie na nasze udokumentowane polecenie. 
                    Poniżej znajdziesz listę odbiorców Twoich danych: 
                </span>

                <span class="question">
                   Każde działanie w związku ze Sklepem 
                </span>

                <span class="answer">
                    Odbiorca danych: Operator internetowej platformy handlowej. Przekazanie danych poza Unię Europejską: Nie ma miejsca.  
                </span>


                <span class="question">
                   Złożenie zamówienia w Sklepie 
                </span>

                <span class="answer">
                    Odbiorca danych: Dostawca płatności. Przekazanie danych poza Unię Europejską: Nie ma miejsca.  
                </span>
                <span class="answer">
                    Odbiorca danych: Podmiot zapewniający dostawę towaru – chyba że wybrałeś opcję odbioru osobistego . Przekazanie danych poza Unię Europejską: Nie ma miejsca.  
                </span>
                <span class="answer">
                    Odbiorca danych: Biuro rachunkowe. Przekazanie danych poza Unię Europejską: Nie ma miejsca.  
                </span>
                <span class="answer">
                    Odbiorca danych: Podmiot zapewniający system ratalny . Przekazanie danych poza Unię Europejską: Nie ma miejsca.  
                </span>

                <span class="question">
                   Wzięcie udziału w badaniu satysfakcji z naszych usług lub produktów
                </span>

                <span class="answer">
                    Odbiorca danych: Podmioty umożliwiające umieszczanie opinii o Sklepie lub produktach i ich porównywanie . Przekazanie danych poza Unię Europejską: Nie ma miejsca.  
                </span>

            </div>

            
        </div>
        <trust/>
        <ftr/>
    </div>
</template>
<script>
// Imports
    import navbar from "../components/navbarStore.vue";
    import trust from "../components/trust.vue";
    import ftr from "../components/footer.vue";

export default {
    data() {
      return {
      }
    },
    mounted()
    {
        window.scrollTo(0,0);
    },
    components: {navbar,trust,ftr},
}
</script>
<style lang="scss" scoped>
    @import '../styles/variables';



    h1 {
        text-align: left;
        user-select: none;
        margin: 0;
        padding: 0;
        font-family: 'Poppins', sans-serif !important;
        font-weight: 500;
        font-size: 1.563rem;
    }

    h3
    {
        text-align: left;
        font-weight: 500;
        padding: 0;
        font-size: 1.25rem;
        margin: 0;
    }

    h4
    {
        text-align: left;
        font-weight: 400;
        padding: 0;
        font-size: 1.25rem;
        margin: 0;
    }

    .privacy
    {
        display: flex;
        flex-direction: column;
        width: 100vw;

        &__content
        {
            width: 90%;
            padding-left: 5%;
            padding-right: 5%;
            margin-top: 10.527em;

            &__info
            {
                margin-top: 1.25rem;
                margin-bottom: 1.25rem;
                display: flex;
                flex-direction: column;
            }
        }
    }

    .question
    {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        font-weight: 500;
    }

    .answer
    {
        font-weight: 400;
    }
    @media (max-width: 769px) {
    .privacy__content{
        margin-top: 4.768em;
    }
  }


</style>